<template>
	<div class="bg_dark bg_intro_2">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf003">
						<div class="intro_logo_title">
							<span class="hide">{{  $language.common.title }}</span>
						</div>
					</div>
					<div class="intro_btn_wrap">
						<div class="btn_area">
							<button class="btn_l btn_fill_blue" @click="$emit('to', { name: 'login'})">{{  $language.common.login }}</button>
						</div>
						<a class="btn_signup" @click="$emit('to', { name: 'agree'})">{{  $language.common.signUp }}</a>
						<p class="copyright">Copyright © MAFI Inc. </p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'mafia003'
	,data: function(){
		return {
			program: {
				name: this.$language.common.login
				, not_header: true
				, not_footer: true
			}
		}
	}
	,methods: {

	}
	,created() {
		this.$emit('onLoad', this.program)
	}
}
</script>